
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import '../styles/landingpage.css'
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Landingpage()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const [landingpageTitel, setLandingPageTitel] = useState('LandingPageTitel');
    const [LandingpageNews, setLandingpageNews] = useState('LandingPageNewsLine');
    const [LandingPageTitelRow1, setLandingPageTitelRow1] = useState('LandingPageTitel_Row1');
    const [LandingPageTitelRow2, setLandingPageTitelRow2] = useState('LandingPageTitel_Row2');
    const [LandingPageNewsElement, setLandingPageNewsElement] = useState('LandingPageNewsElement');
    const [NewsElementDate, setNewsElementDate] = useState('NewsElementDate');
    const [NewsElementTitel, setNewsElementTitel] = useState('NewsElementTitel');
    const [LandingpageBanner, setLandingpageBanner] = useState('LandingpageBanner');
    const [Christmas, setChristmas] = useState(false);

    useEffect(()=>{
        if(Christmas == true)
            {
                setLandingpageBanner('LandingpageBanner_Christmas');
            }
    }, [Christmas])

    

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setLandingPageTitel('LandingPageTitel_Mobile');
            setLandingpageNews('LandingPageNewsLine_Mobile');
            setLandingPageTitelRow1('LandingPageTitel_Row1_Mobile');
            setLandingPageTitelRow2('LandingPageTitel_Row2_Mobile');
            setLandingPageNewsElement('LandingPageNewsElement_Mobile');
            setNewsElementDate('NewsElementDate_Mobile');
            setNewsElementTitel('NewsElementTitel_Mobile');
            setLandingpageBanner('LandingpageBanner_Mobile')
        }

    }, windowSize.current[0])

    return(
        <div className='LandingpageWrapper'>
            <Navigation></Navigation>
            <div className={LandingpageBanner}></div>
            <div className={landingpageTitel}>
                <div className={LandingPageTitelRow1}>Lesedidaktik und Lesediagnostik</div>
                <div className={LandingPageTitelRow2}>Schreibdidaktik und Schreiben mit KI </div>
            </div>
            <div className={LandingpageNews}>

            <Link to="https://www.tandfonline.com/doi/full/10.1080/21532974.2024.2431747?scroll=top&needAccess=true"><div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>9.12.2024</div>
                    <div className={NewsElementTitel}>Neuer internationaler Beitrag in JDLTE</div>
            </div></Link>

            <Link to="/news"><div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>15.11.2024</div>
                    <div className={NewsElementTitel}>Keynote auf dem Zukunftsforum Bildungsforschung</div>
            </div></Link>  

            <Link to="/news"> <div className={LandingPageNewsElement}>
                    <div className={NewsElementDate}>7.11.2024</div>
                    <div className={NewsElementTitel}>Workshop auf dem 1. Jenaer Fachtag Deutsch</div>
                </div>
            </Link>

            

           

               
            </div>
        </div>

    )
}

export default Landingpage